<template>
  <div>
    <el-breadcrumb style="margin: 0 0 20px 10px" separator="/">
      <el-breadcrumb-item>常规配置</el-breadcrumb-item>
      <el-breadcrumb-item>系统配置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="tt">系统配置</div>
      <div class="xian"></div>
      <el-form
        v-if="show"
        :model="form"
        :rules="rules"
        ref="enterForm"
        label-width="140px"
        style="width: 100%"
      >
        <el-form-item
          :label="it.remark + ':'"
          v-for="(it, i) in infoconfigList"
          :key="i"
        >
          <span>{{ it.value }}</span>
        </el-form-item>
      </el-form>
      <el-form
        v-else
        :model="form"
        :rules="rules"
        ref="enterForm"
        label-width="140px"
        style="width: 100%"
      >
        <el-form-item
          :label="it.remark + ':'"
          v-for="(it, i) in infoconfigList"
          :key="i"
          prop="value"
        >
          <el-date-picker value-format="yyyy-MM-dd" v-model="form.value" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="btn_box" v-if="show">
        <el-button size="small" type="cha" class="add_btn" @click="bianji"
          >配置</el-button
        >
      </div>
      <div class="btn_box" v-else>
        <el-button size="small" type="cha" class="add_btn" @click="baocun"
          >保存</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        value: '',
      },
      rules: {
        value: [{ required: true, message: '请选择日期', trigger: 'blur' }],
      },
      show: true,
      infoconfigList: [],
    }
  },
  created() {
    this.info()
  },
  methods: {
    //  获取配置信息
    async info() {
      const { data: res } = await this.$http.get('api/infoconfig')
      console.log(res)
      this.infoconfigList = res.data
    },
    bianji() {
      this.show = false
    },
    // 编辑配置信息
    baocun() {
      this.$refs.enterForm.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整数据')
        const { data: res } = await this.$http.post('api/editconfig', {
          new_semester: this.form.value
        })
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.show = true
        this.form.value = ''
        this.info()
      })
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 13px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 20px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 13px auto 20px;
  }

  .int {
    width: 400px;
  }

  .add_btn {
    // width: 100px;
    margin: 60px 0 100px 140px;
  }
}
</style>

